import React, { Fragment } from "react";
import { Route, Switch } from "react-router";
import Pathname from "../../app/naming/Pathname";
import NotificationMessage from "../../app/ui/snackbar/NotificationMessage";
import Ndxr from "./Ndxr";
import JigyoshoCsv from "./JigyoshoCsv";

class ToolsRouter extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Fragment>
        <Switch>
          <Route path={Pathname.tools_ndxr} component={Ndxr} />
        </Switch>
        <Switch>
          <Route path={Pathname.tools_jigyosho_csv} component={JigyoshoCsv} />
        </Switch>
        <NotificationMessage />
      </Fragment>
    );
  }
}

export default ToolsRouter;
