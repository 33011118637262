import ServiceShubetsuType from "./ServiceShubetsuType";

const JigyoshoAttributeType = {
  // 保険内（標準）
  jigyoshoJoho: { name: "jigyoshoJoho", label: "基本情報", orderNo: 1 },
  eigyoJikan: { name: "eigyoJikan", label: "営業時間", orderNo: 2 },

  // 保険内（訪問系サービス）
  homonkeiServiceJigyosho: {
    name: "homonkeiServiceJigyosho",
    label: "基本情報",
    orderNo: 1,
  },
  homonkeiServiceEigyoJikan: {
    name: "homonkeiServiceEigyoJikan",
    label: "営業時間",
    orderNo: 2,
  },
  homonkeiServiceAkijoho: {
    name: "homonkeiServiceAkijoho",
    label: "空き情報",
    orderNo: 3,
  },
  homonkeiServiceServiceTeikyo: {
    name: "homonkeiServiceServiceTeikyo",
    label: "サービス提供地域・訪問手段",
    orderNo: 4,
  },
  homonkeiServiceKaigo: {
    name: "homonkeiServiceKaigo",
    label: "介護サービス",
    orderNo: 5,
  },
  homonkeiServiceKango: {
    name: "homonkeiServiceKango",
    label: "看護サービス",
    orderNo: 6,
  },
  homonkeiServiceNyuyoku: {
    name: "homonkeiServiceNyuyoku",
    label: "入浴サービス",
    orderNo: 7,
  },
  homonkeiServiceRehabilitation: {
    name: "homonkeiServiceRehabilitation",
    label: null,
    orderNo: 8,
    serviceShubetsuAssociatedLabel: (serviceShubetsuCode) =>
      [ServiceShubetsuType.訪問リハビリテーション, ServiceShubetsuType.訪問看護]
        .map((it) => it.code)
        .includes(serviceShubetsuCode)
        ? "リハビリテーション"
        : "機能訓練",
  },
  homonkeiServiceHokengaiService: {
    name: "homonkeiServiceHokengaiService",
    label: "保険外サービス",
    orderNo: 9,
  },
  homonkeiServiceTaiokanoShojo: {
    name: "homonkeiServiceTaiokanoShojo",
    label: "受入れ / 対応可能な症状・医療行為",
    orderNo: 10,
  },
  homonkeiServiceRiyosha: {
    name: "homonkeiServiceRiyosha",
    label: "利用者",
    orderNo: 11,
  },
  homonkeiServiceStaff: {
    name: "homonkeiServiceStaff",
    label: "スタッフ",
    orderNo: 12,
  },
  homonkeiServiceRyokinkasan: {
    name: "homonkeiServiceRyokinkasan",
    label: "料金加算",
    orderNo: 13,
  },
  homonkeiServiceBihin: {
    name: "homonkeiServiceBihin",
    label: "備品",
    orderNo: 14,
  },
  homonkeiServiceGaibuRenkei: {
    name: "homonkeiServiceGaibuRenkei",
    label: "外部連携",
    orderNo: 15,
  },

  // 保険内（通所系サービス）
  tsushokeiServiceJigyoshoJoho: {
    name: "tsushokeiServiceJigyoshoJoho",
    label: "基本情報",
    orderNo: 3,
  },
  tsushokeiServiceJigyoshoEigyoJikan: {
    name: "tsushokeiServiceJigyoshoEigyoJikan",
    label: "営業時間",
    orderNo: 4,
  },
  tsushokeiServiceJigyoshoAkiJoho: {
    name: "tsushokeiServiceJigyoshoAkiJoho",
    label: "空き情報(更新日反映)",
    orderNo: 5,
  },
  serviceTeikyoJikan: {
    name: "serviceTeikyoJikan",
    label: "サービス提供時間",
    orderNo: 6,
  },
  sogei: { name: "sogei", label: "サービス提供地域・送迎", orderNo: 7 },
  rehabilitation: {
    name: "rehabilitation",
    label: null,
    orderNo: 8,
    serviceShubetsuAssociatedLabel: (serviceShubetsuCode) =>
      [ServiceShubetsuType.通所リハビリテーション]
        .map((it) => it.code)
        .includes(serviceShubetsuCode)
        ? "リハビリテーション"
        : "機能訓練",
  },
  recreation: { name: "recreation", label: "レクリエーション", orderNo: 9 },
  shokuji: { name: "shokuji", label: "食事", orderNo: 10 },
  nyuyoku: { name: "nyuyoku", label: "入浴", orderNo: 11 },
  haisetsu: { name: "haisetsu", label: "排泄", orderNo: 12 },
  taiokanoNaShojo: {
    name: "taiokanoNaShojo",
    label: "受入れ / 対応可能な症状・医療行為",
    orderNo: 13,
  },
  riyosha: { name: "riyosha", label: "利用者", orderNo: 14 },
  staff: { name: "staff", label: "スタッフ", orderNo: 15 },
  ryokinKasanJoho: {
    name: "ryokinKasanJoho",
    label: "料金加算情報",
    orderNo: 16,
  },
  tatemonoSetsubiBihin: {
    name: "tatemonoSetsubiBihin",
    label: "建物・設備・備品",
    orderNo: 17,
  },
  gaibuRenkei: { name: "gaibuRenkei", label: "外部連携", orderNo: 18 },
  tsushokeiServiceJigyoshoSonota: {
    name: "tsushokeiServiceJigyoshoSonota",
    label: "その他サービス",
    orderNo: 19,
  },

  // ショートステイ
  shortstayjigyosho: {
    name: "shortstayJigyosho",
    label: "基本情報",
    orderNo: 1,
  },
  shortstayEigyoJikan: {
    name: "shortstayEigyoJikan",
    label: "営業時間",
    orderNo: 2,
  },
  shortstaySogei: {
    name: "shortstaySogei",
    label: "サービス提供地域・送迎",
    orderNo: 3,
  },
  shortstayRehabilitation: {
    name: "shortstayRehabilitation",
    label: null,
    orderNo: 4,
    serviceShubetsuAssociatedLabel: (serviceShubetsuCode) =>
      [ServiceShubetsuType.短期入所生活介護]
        .map((it) => it.code)
        .includes(serviceShubetsuCode)
        ? "機能訓練"
        : "リハビリテーション",
  },
  shortstayRecreation: {
    name: "shortstayRecreation",
    label: "レクリエーション",
    orderNo: 5,
  },
  shortstayShokuji: { name: "shortstayShokuji", label: "食事", orderNo: 6 },
  shortstayNyuyoku: { name: "shortstayNyuyoku", label: "入浴", orderNo: 7 },
  shortstayHaisetsu: { name: "shortstayHaisetsu", label: "排泄", orderNo: 8 },
  shortstayKyoshitsu: { name: "shortstayKyoshitsu", label: "居室", orderNo: 9 },
  shortstayTaiokanoShojo: {
    name: "shortstayTaiokanoShojo",
    label: "受入れ / 対応可能な症状・医療行為",
    orderNo: 10,
  },
  shortstayRiyosha: { name: "shortstayRiyosha", label: "利用者", orderNo: 11 },
  shortstayStaff: { name: "shortstayStaff", label: "スタッフ", orderNo: 12 },
  shortstayRyokinkasan: {
    name: "shortstayRyokinkasan",
    label: "料金加算情報",
    orderNo: 13,
  },
  shortstayRyokinmeyasu: {
    name: "shortstayRyokinmeyasu",
    label: "利用料金目安",
    orderNo: 14,
  },
  shortstayMoshikomi: {
    name: "shortstayMoshikomi",
    label: "申込み情報",
    orderNo: 15,
  },
  shortstayTatemonoSetsubiBihin: {
    name: "shortstayTatemonoSetsubiBihin",
    label: "建物・設備・備品",
    orderNo: 16,
  },
  shortstayGaibuRenkei: {
    name: "shortstayGaibuRenkei",
    label: "外部連携",
    orderNo: 17,
  },
  shortstaySonotaService: {
    name: "shortstaySonotaService",
    label: "その他サービス",
    orderNo: 18,
  },

  // 居宅介護支援
  kyotakukaigoshienjigyoshoJoho: {
    name: "kyotakukaigoshienjigyoshoJoho",
    label: "基本情報",
    orderNo: 1,
  },
  kyotakukaigoshienjigyoshoEigyoJikan: {
    name: "kyotakukaigoshienjigyoshoEigyoJikan",
    label: "営業時間",
    orderNo: 2,
  },
  kyotakukaigoshienjigyoshoService: {
    name: "kyotakukaigoshienjigyoshoService",
    label: "訪問・電話対応",
    orderNo: 3,
  },
  kyotakukaigoshienjigyoshoStaff: {
    name: "kyotakukaigoshienjigyoshoStaff",
    label: "スタッフ",
    orderNo: 4,
  },
  kyotakukaigoshienjigyoshoRyokinkasan: {
    name: "kyotakukaigoshienjigyoshoRyokinkasan",
    label: "料金加算",
    orderNo: 5,
  },

  // 保険外
  business: { name: "business", label: "基本情報", orderNo: 1 },
  promotion: { name: "promotion", label: "特徴・メッセージ", orderNo: 2 },
  delivery: { name: "delivery", label: "サービス・料金について", orderNo: 3 },
  deliveryMenu: {
    name: "deliveryMenu",
    label: "メニュー・プランについて",
    orderNo: 4,
  },
  deliveryOption: { name: "deliveryOption", label: "オプション", orderNo: 5 },
  taxi: { name: "taxi", label: "サービス・料金について", orderNo: 6 },
  taxiMenu: { name: "taxiMenu", label: "メニュー・プランについて", orderNo: 7 },
  taxiOption: { name: "taxiOption", label: "オプション", orderNo: 8 },
  housework: { name: "housework", label: "サービス・料金について", orderNo: 9 },
  houseworkMenu: {
    name: "houseworkMenu",
    label: "メニュー・プランについて",
    orderNo: 10,
  },
  businessProvideOther: {
    name: "businessProvideOther",
    label: "サービス・料金について",
    orderNo: 11,
  },
  businessProvideOtherMenu: {
    name: "businessProvideOtherMenu",
    label: "メニュー・活動内容について",
    orderNo: 12,
  },
  homeProvideOther: {
    name: "homeProvideOther",
    label: "サービス・料金について",
    orderNo: 13,
  },
  homeProvideOtherMenu: {
    name: "homeProvideOtherMenu",
    label: "メニュー・活動内容について",
    orderNo: 14,
  },
  barber: { name: "barber", label: "サービス・お支払いについて", orderNo: 15 },
  barberMenu: {
    name: "barberMenu",
    label: "メニュー・プランについて",
    orderNo: 16,
  },
  memento: {
    name: "memento",
    label: "サービス・お支払いについて",
    orderNo: 17,
  },
  mementoMenu: {
    name: "mementoMenu",
    label: "サービス・プランについて",
    orderNo: 18,
  },
  watch: { name: "watch", label: "サービス・お支払いについて", orderNo: 19 },
  watchMenu: {
    name: "watchMenu",
    label: "サービス・プランについて",
    orderNo: 20,
  },
  watchOption: { name: "watchOption", label: "オプション", orderNo: 21 },
  mimamoriSuishinJigyo: {
    name: "mimamoriSuishinJigyo",
    label: "基本情報",
    orderNo: 22,
  },
  mimamoriSuishinJigyoDetail: {
    name: "mimamoriSuishinJigyoDetail",
    label: "詳細",
    orderNo: 23,
  },
  jushoJigyoshoJoho: {
    name: "jushoJigyoshoJoho",
    label: "住所変更(保険内)",
    orderNo: 24,
  },
  jushoHokengaiJigyoshoJoho: {
    name: "jushoHokengaiJigyoshoJoho",
    label: "住所変更(保険外)",
    orderNo: 25,
  },
};

export function jigyoshoAttributeTypeValues() {
  return Object.keys(JigyoshoAttributeType)
    .map((key) => JigyoshoAttributeType[key])
    .sort((a, b) => a.orderNo - b.orderNo);
}

export function jigyoshoAttributeTypeValueOf(name) {
  return jigyoshoAttributeTypeValues().find((type) => type.name == name);
}

export function jigyoshoAttributeLabelOf(
  jigyoshoAttributeType,
  serviceShubetsuCode
) {
  return (
    jigyoshoAttributeType.label ||
    jigyoshoAttributeType.serviceShubetsuAssociatedLabel(serviceShubetsuCode)
  );
}

export default JigyoshoAttributeType;
