import React from "react";
import PropTypes from "prop-types";
import ServiceShubetsuType from "../../../../app/domain/jigyosho/ServiceShubetsuType";

const CardFoot = (props) => {
  const id = [ServiceShubetsuType.活動_通いの場]
    .map((it) => it.code)
    .includes(props.service.serviceShubetsuCode)
    ? props.service.serviceId
    : props.service.id;
  return (
    <div className="c-card-frame__item-body-footer">
      <div
        className={`c-button-rb${
          props.shared ? "-off" : ""
        } c-button-rb--shared-folder`}
        onClick={() => props.onShareButtonClick(id, props.service.serviceSubId)}
      >
        <a>{props.shared ? "共有フォルダから削除" : "共有フォルダに追加"}</a>
      </div>
      <div className="c-button-bb">
        <a
          onClick={() =>
            props.onPrintButtonClick(id, props.service.serviceSubId)
          }
        >
          印刷
        </a>
      </div>
      {/*
            <object className="c-button-gb-off">
                <a>マイリストから削除</a>
            </object>
            */}
      <div className="c-card-frame__item-body-footer-detail">
        <p
          onClick={() =>
            props.onServiceButtonClick(id, props.service.serviceSubId)
          }
        >
          詳細を見る
        </p>
      </div>
    </div>
  );
};

CardFoot.propTypes = {
  onPrintButtonClick: PropTypes.func.isRequired,
  onServiceButtonClick: PropTypes.func.isRequired,
  onShareButtonClick: PropTypes.func.isRequired,
  service: PropTypes.object,
  shared: PropTypes.bool.isRequired,
};

export default CardFoot;
