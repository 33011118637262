import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import OperationDialog from "../../../../app/ui/dialog/OperationDialog";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import Pathname from "../../../../app/naming/Pathname";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import RaisedSecondaryButton from "../../../../app/ui/button/RaisedSecondaryButton";
import * as actions from "../../../../state/jigyosho-form/actions";
import BackOfficeSubHead from "../../../../app/ui/structure/BackOfficeSubHead";
import Mount from "../../../../app/ui/layout/Mount";

class JigyoshoListOperationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleStatusButtonClick = this.handleStatusButtonClick.bind(this);
    this.handleCareManagerUserButtonClick = this.handleCareManagerUserButtonClick.bind(
      this
    );
    this.handleRemoveButtonClick = this.handleRemoveButtonClick.bind(this);
    this.handleRemoveDialogCloseButtonClick = this.handleRemoveDialogCloseButtonClick.bind(
      this
    );
    this.handleRemoveDialogRevemoButtonClick = this.handleRemoveDialogRevemoButtonClick.bind(
      this
    );
    this.handlePasswordButtonClick = this.handlePasswordButtonClick.bind(this);
    this.handleKanriAccountEditButtonClick = this.handleKanriAccountEditButtonClick.bind(
      this
    );
    this.handleKanriAccountAddButtonClick = this.handleKanriAccountAddButtonClick.bind(
      this
    );
  }

  state = {
    dialogIsOpen: false,
    isDeleteButton: false,
  };

  handleStatusButtonClick() {
    this.props.onEditButtonClick(this.props.target.id);
  }

  handleCareManagerUserButtonClick() {
    this.props.onCareManagerUserButtonClick(this.props.target.groupId);
  }

  handleRemoveButtonClick() {
    this.setState({ dialogIsOpen: true, isDeleteButton: false });
  }

  handleRemoveDialogCloseButtonClick() {
    this.setState({ dialogIsOpen: false });
  }

  handleRemoveDialogRevemoButtonClick() {
    this.setState({ isDeleteButton: true });
    this.props.onRemoveButtonClick(this.props.target.id, () => {
      this.handleRemoveDialogCloseButtonClick();
      this.props.onRemoveDone(this.props.search);
    });
  }

  handleKanriAccountAddButtonClick() {
    this.props.onKanriAccountAddButtonClick(this.props.target.groupId);
  }

  handleKanriAccountEditButtonClick() {
    this.props.onKanriAccountEditButtonClick(this.props.target.accountId);
  }

  handlePasswordButtonClick() {
    this.props.onPasswordButtonClick(this.props.target.accountId);
  }

  render() {
    return (
      <Fragment>
        {this.renderOperationDialog()}
        {this.renderRemoveDialog()}
      </Fragment>
    );
  }

  renderOperationDialog() {
    const { onClose = () => {}, target = {}, user } = this.props;
    const open = target.id ? true : false;
    return (
      <OperationDialog
        onClose={onClose}
        open={open}
        title={`【${target.serviceShubetsu}】 ${target.jigyoshomei}`}
      >
        <Mount
          if={target.searchableInFrontend || user.hasBackOfficeHojinMenu()}
        >
          <SpaceOut>
            <RaisedPrimaryButton onClick={this.handleStatusButtonClick}>
              入力状況を表示する
            </RaisedPrimaryButton>
          </SpaceOut>
        </Mount>
        <Mount if={target.shienCenter}>
          <SpaceOut>
            <RaisedPrimaryButton
              onClick={this.handleCareManagerUserButtonClick}
            >
              一般ユーザーを管理する
            </RaisedPrimaryButton>
          </SpaceOut>
        </Mount>
        <Mount if={user.canRemoveJigyosho()}>
          <SpaceOut>
            <FlatSecondaryButton onClick={this.handleRemoveButtonClick}>
              削除する
            </FlatSecondaryButton>
          </SpaceOut>
        </Mount>
        <BackOfficeSubHead>
          事業所管理アカウント ({target.username ? target.username : "なし"})
        </BackOfficeSubHead>
        <Mount if={target.username}>
          <Fragment>
            <Mount if={user.canEditJigyoshoAdmin()}>
              <SpaceOut>
                <RaisedPrimaryButton
                  onClick={this.handleKanriAccountEditButtonClick}
                >
                  アカウント情報を変更する
                </RaisedPrimaryButton>
              </SpaceOut>
            </Mount>
            <Mount if={user.canChangeJigyoshoAdminPassword()}>
              <SpaceOut>
                <RaisedPrimaryButton onClick={this.handlePasswordButtonClick}>
                  パスワードを変更する
                </RaisedPrimaryButton>
              </SpaceOut>
            </Mount>
          </Fragment>
        </Mount>
        <Mount if={!target.username}>
          <Mount if={user.canAddJigyoshoAdmin()}>
            <SpaceOut>
              <RaisedPrimaryButton
                onClick={this.handleKanriAccountAddButtonClick}
              >
                管理アカウントを作成する
              </RaisedPrimaryButton>
            </SpaceOut>
          </Mount>
        </Mount>
      </OperationDialog>
    );
  }

  renderRemoveDialog() {
    return (
      <Dialog
        onClose={this.handleRemoveDialogCloseButtonClick}
        open={this.state.dialogIsOpen}
      >
        <DialogTitle>事業所削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            事業所データを削除します、よろしいですか？
          </DialogContentText>
          <DialogContentText>
            事業所データを削除した場合、新規に登録し直すまでこの事業所を検索することができなくなります。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton onClick={this.handleRemoveDialogCloseButtonClick}>
            キャンセル
          </FlatPrimaryButton>
          <RaisedSecondaryButton
            onClick={this.handleRemoveDialogRevemoButtonClick}
            disabled={this.state.isDeleteButton}
          >
            削除する
          </RaisedSecondaryButton>
        </DialogActions>
      </Dialog>
    );
  }
}

JigyoshoListOperationDialog.propTypes = {
  // state
  search: PropTypes.string,
  target: PropTypes.object,
  user: PropTypes.object.isRequired,
  // action
  onClose: PropTypes.func,
  onEditButtonClick: PropTypes.func.isRequired,
  onCareManagerUserButtonClick: PropTypes.func.isRequired,
  onKanriAccountAddButtonClick: PropTypes.func.isRequired,
  onKanriAccountEditButtonClick: PropTypes.func.isRequired,
  onPasswordButtonClick: PropTypes.func.isRequired,
  onRemoveButtonClick: PropTypes.func.isRequired,
  onRemoveDone: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JigyoshoListOperationDialog);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    onCareManagerUserButtonClick: (groupId) => {
      const searchParams = new URLSearchParams();
      searchParams.set("groupId", groupId);
      dispatch(
        push({
          pathname: Pathname.backoffice_jigyosho_caremanagers,
          search: searchParams.toString(),
        })
      );
    },
    onRemoveButtonClick: (id, callback) => {
      dispatch(actions.submitToRemove(id, callback));
    },
    onRemoveDone: (search = "") => {
      const searchParams = new URLSearchParams(search);
      searchParams.set("timestamp", new Date().getTime());
      search = searchParams.toString();
      dispatch(push({ search }));
    },
    onKanriAccountAddButtonClick: (groupId) => {
      const searchParams = new URLSearchParams();
      searchParams.set("groupId", groupId);
      dispatch(
        push({
          pathname: Pathname.backoffice_jigyosho_kanriaccount,
          search: searchParams.toString(),
        })
      );
    },
    onKanriAccountEditButtonClick: (accountId) =>
      dispatch(
        push(
          Pathname.backoffice_jigyosho_accounts_id_edit.replace(
            /:id/,
            accountId
          )
        )
      ),
    onPasswordButtonClick: (accountId) =>
      dispatch(
        push(
          Pathname.backoffice_jigyosho_accounts_id_password.replace(
            /:id/,
            accountId
          )
        )
      ),
  };
}
