import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../state/account-form/actions";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import { withInputFeedback } from "../../../../app/ui/form";
import HorizontalLabelLayout from "../../../../app/ui/form/HorizontalLabelLayout";
import FormGrouping from "../../../../app/ui/form/FormGrouping";
import { Card } from "@material-ui/core";
import Boundary from "../../../../app/ui/layout/Boundary";
import Right from "../../../../app/ui/layout/Right";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import ShortInputWidth from "../../../../app/ui/form/ShortInputWidth";
import InputTypeText from "../../../../app/ui/form/InputTypeText";
import GridRow from "../../../../app/ui/grid/GridRow";
import GridCell from "../../../../app/ui/grid/GridCell";
import StaticLabel from "../../../../app/ui/form/StaticLabel";

class AccountEditComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
  }

  componentDidMount() {
    const { actions, id } = this.props;
    actions.requestEditForm(id);
  }

  handleBackButtonClick() {
    const { onGoBack } = this.props;
    onGoBack();
  }

  handleSaveButtonClick() {
    const { actions, accountForm, id, onGoAhead } = this.props;
    actions.submitToEdit(
      id,
      accountForm.form,
      onGoAhead ? () => onGoAhead() : this.handleBackButtonClick
    );
  }

  createBindingProps() {
    const { actions, accountForm } = this.props;
    const { form } = accountForm;
    const { changeForm: onChange } = actions;
    return { form, onChange };
  }

  render() {
    return (
      <Boundary>
        <SideMargin>
          {this.renderHead()}
          {this.renderNavigation()}
          {this.renderForm()}
        </SideMargin>
      </Boundary>
    );
  }

  renderHead() {
    return <BackOfficeHead>アカウント編集</BackOfficeHead>;
  }

  renderNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }

  renderForm() {
    const { form, formIsLoading, formIsSubmitting } = this.props.accountForm;
    if (formIsLoading) return <CenteredCircularProgress />;
    if (!form.typeIs("edit")) return null;
    const bindingProps = this.createBindingProps();
    return (
      <Card>
        <SideMargin top bottom>
          <FormGrouping title="ログイン情報">
            <HorizontalLabelLayout labelText="ログインID" required>
              <ShortInputWidth>
                <InputTypeText name="username" {...bindingProps} />
              </ShortInputWidth>
              <StaticLabel>
                ※ ログインIDは半角英数字で入力してください
                <br />
              </StaticLabel>
            </HorizontalLabelLayout>
          </FormGrouping>
          <FormGrouping title="アカウント情報">
            <HorizontalLabelLayout labelText="苗字・名前" required>
              <GridRow>
                <GridCell>
                  <SpaceOut block>
                    <InputTypeText
                      name="familyName"
                      hintText="山田"
                      {...bindingProps}
                    />
                  </SpaceOut>
                </GridCell>
                <GridCell>
                  <SpaceOut block>
                    <InputTypeText
                      name="givenName"
                      hintText="太郎"
                      {...bindingProps}
                    />
                  </SpaceOut>
                </GridCell>
                <GridCell></GridCell>
              </GridRow>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="✉ メールアドレス">
              <InputTypeText
                name="mailAddress"
                hintText="xxxx@xxxx.xxxx"
                {...bindingProps}
              />
            </HorizontalLabelLayout>
          </FormGrouping>
          <Right>
            <SpaceOut>
              <FlatSecondaryButton onClick={this.handleBackButtonClick}>
                キャンセル
              </FlatSecondaryButton>
            </SpaceOut>
            <SpaceOut>
              <RaisedPrimaryButton
                disabled={formIsSubmitting}
                onClick={this.handleSaveButtonClick}
              >
                保存する
              </RaisedPrimaryButton>
            </SpaceOut>
          </Right>
        </SideMargin>
      </Card>
    );
  }
}

AccountEditComponent.propTypes = {
  accountForm: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  id: PropTypes.number.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onGoAhead: PropTypes.func,
};

export default withInputFeedback(
  connect(mapStateToProps, mapDispatchToProps)(AccountEditComponent)
);

function mapStateToProps(state) {
  return {
    accountForm: state.accountForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
