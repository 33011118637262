import React from "react";
import { Route, Switch } from "react-router";
import Pathname from "../../../app/naming/Pathname";
import JigyoshoListPage from "./list/JigyoshoListPage";
import JigyoshoPasswordPage from "./password/JigyoshoPasswordPage";
import JigyoshoAddPage from "./add/JigyoshoAddPage";
import JigyoshoStatusPage from "./status/JigyoshoStatusPage";
import JigyoshoAttributePage from "./attribute/JigyoshoAttributePage";
import OpenDataJoinPage from "./join/OpenDataJoinPage";
import JigyoshoKanriAccountEditPage from "./kanriaccount/JigyoshoKanriAccountEditPage";
import JigyoshoKanriAccountPage from "./kanriaccount/JigyoshoKanriAccountPage";
import JigyoshoFlierCollectivePage from "./flier/JigyoshoFlierCollectivePage";
import JigyoshoPhotoCollectivePage from "./photo/JigyoshoPhotoCollectivePage";
import CareManagerUserListPage from "./caremanageruser/CareManagerUserListPage";
import CareManagerUserAddPage from "./caremanageruser/CareManagerUserAddPage";
import CareManagerUserEditPage from "./caremanageruser/CareManagerUserEditPage";
import CareManagerUserPasswordPage from "./caremanageruser/CareManagerUserPasswordPage";

const JigyoshoRouter = () => {
  return (
    <Switch>
      <Route
        path={Pathname.backoffice_jigyosho_join}
        component={OpenDataJoinPage}
      />
      <Route
        path={Pathname.backoffice_jigyosho_add}
        component={JigyoshoAddPage}
      />
      <Route
        path={Pathname.backoffice_jigyosho_id_status}
        component={JigyoshoStatusPage}
      />
      <Route
        path={Pathname.backoffice_jigyosho_id_photo}
        component={(props) => (
          <JigyoshoPhotoCollectivePage
            statusPathname={Pathname.backoffice_jigyosho_id_status}
            {...props}
          />
        )}
      />
      <Route
        path={Pathname.backoffice_jigyosho_id_flier}
        component={(props) => (
          <JigyoshoFlierCollectivePage
            statusPathname={Pathname.backoffice_jigyosho_id_status}
            {...props}
          />
        )}
      />
      <Route
        path={Pathname.backoffice_jigyosho_id_attributes_type}
        component={(props) => (
          <JigyoshoAttributePage
            statusPathname={Pathname.backoffice_jigyosho_id_status}
            {...props}
          />
        )}
      />
      <Route
        path={Pathname.backoffice_jigyosho_kanriaccount}
        component={JigyoshoKanriAccountPage}
      />
      <Route
        path={Pathname.backoffice_jigyosho_caremanagers_add}
        component={CareManagerUserAddPage}
      />
      <Route
        path={Pathname.backoffice_jigyosho_caremanagers_id_edit}
        component={CareManagerUserEditPage}
      />
      <Route
        path={Pathname.backoffice_jigyosho_caremanagers_id_password}
        component={CareManagerUserPasswordPage}
      />
      <Route
        path={Pathname.backoffice_jigyosho_caremanagers}
        component={CareManagerUserListPage}
      />
      <Route
        path={Pathname.backoffice_jigyosho_accounts_id_edit}
        component={JigyoshoKanriAccountEditPage}
      />
      <Route
        path={Pathname.backoffice_jigyosho_accounts_id_password}
        component={JigyoshoPasswordPage}
      />
      <Route path={Pathname.backoffice_jigyosho} component={JigyoshoListPage} />
    </Switch>
  );
};

export default JigyoshoRouter;
