import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as formActions from "../../../../state/account-form/actions";
import * as listActions from "../../../../state/account-list/actions";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import {
  Paper,
  TableBody,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { css } from "aphrodite";
import styles from "./AccountListComponent.css";
import SmallPaddingTableCell from "../../../../app/ui/table/SmallPaddingTableCell";
import Right from "../../../../app/ui/layout/Right";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PasswordIcon from "@material-ui/icons/VpnKey";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import Boundary from "../../../../app/ui/layout/Boundary";
import { push } from "react-router-redux";
import FixedTable from "../../../../app/ui/table/FixedTable";
import { withInputFeedback } from "../../../../app/ui/form";
import { roleTypeValueOf } from "../../../../app/domain/account/RoleType";
import RaisedSecondaryButton from "../../../../app/ui/button/RaisedSecondaryButton";

class AccountListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.resetSelection = this.resetSelection.bind(this);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handlePasswordButtonClick = this.handlePasswordButtonClick.bind(this);
    this.handleRemoveButtonClick = this.handleRemoveButtonClick.bind(this);
    this.handleRemoveDialogCloseButtonClick = this.handleRemoveDialogCloseButtonClick.bind(
      this
    );
    this.handleRemoveDialogRevemoButtonClick = this.handleRemoveDialogRevemoButtonClick.bind(
      this
    );
  }

  state = {
    removeDialogIsOpen: false,
    removeTargetId: undefined,
    isDeleteButton: false,
  };

  componentDidMount() {
    const { actions, groupId, role } = this.props;
    actions.requestByGroupIdAndRole(groupId, role);
    this.resetSelection();
  }

  resetSelection() {
    const { actions } = this.props;
    actions.select(undefined);
  }

  handleBackButtonClick() {
    const { onGoBack } = this.props;
    onGoBack();
  }

  handleEditButtonClick(e, id) {
    e.stopPropagation();
    const { onEditButtonClick } = this.props;
    onEditButtonClick(id);
  }

  handlePasswordButtonClick(e, id) {
    e.stopPropagation();
    const { onPasswordButtonClick } = this.props;
    onPasswordButtonClick(id);
  }

  handleRemoveButtonClick(e, id) {
    e.stopPropagation();
    this.setState({
      removeDialogIsOpen: true,
      removeTargetId: id,
      isDeleteButton: false,
    });
  }

  handleRemoveDialogCloseButtonClick(e) {
    e.stopPropagation();
    this.setState({
      removeDialogIsOpen: false,
      removeTargetId: undefined,
    });
  }

  handleRemoveDialogRevemoButtonClick(e) {
    e.stopPropagation();
    const { actions } = this.props;
    this.setState({ isDeleteButton: true });
    actions.submitToRemove(this.state.removeTargetId, () => {
      actions.reloadPage(this.props.groupId);
    });
  }

  accountLabel() {
    return this.props.role
      ? roleTypeValueOf(this.props.role).label
      : "アカウント";
  }

  render() {
    return (
      <Fragment>
        <Boundary>
          <SideMargin>
            {this.renderHead()}
            {this.renderNavigation()}
            {this.renderTable()}
          </SideMargin>
        </Boundary>
        {this.renderRemoveDialog()}
      </Fragment>
    );
  }

  renderHead() {
    return <BackOfficeHead>{this.accountLabel()}一覧</BackOfficeHead>;
  }

  renderNavigation() {
    const { opsNavigation, onGoBack } = this.props;
    return (
      <Fragment>
        {onGoBack ? (
          <BackOfficeNavigation>
            <FlatPrimaryButton onClick={this.handleBackButtonClick}>
              <BackIcon />
              戻る
            </FlatPrimaryButton>
          </BackOfficeNavigation>
        ) : null}
        {opsNavigation}
      </Fragment>
    );
  }

  renderTable() {
    const { actions, accountList } = this.props;
    const { list, selectedId } = accountList;
    const hojinIsSelected = (hojin) => hojin.id === selectedId;
    return (
      <Paper>
        <FixedTable>
          <TableHead>
            <TableRow>
              <SmallPaddingTableCell>
                氏名
                <br />
                ログインID
              </SmallPaddingTableCell>
              <SmallPaddingTableCell>ロール</SmallPaddingTableCell>
              <SmallPaddingTableCell>メールアドレス</SmallPaddingTableCell>
              <SmallPaddingTableCell
                className={css(styles.shortcutCell)}
              ></SmallPaddingTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((account) => {
              const onRowClick = (e) => {
                e.stopPropagation();
                actions.select(account.id);
              };
              const rowClass = hojinIsSelected(account)
                ? css(styles.selectableRow, styles.selectedRow)
                : css(styles.selectableRow);
              const cellClass = hojinIsSelected(account)
                ? css(styles.selectedCell)
                : "";
              return (
                <TableRow
                  className={rowClass}
                  key={account.id}
                  onClick={onRowClick}
                >
                  <SmallPaddingTableCell className={cellClass}>
                    {account.familyName}
                    {account.givenName}
                    <br />
                    {account.username}
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell className={cellClass}>
                    {account.roles.map((role) => (
                      <div key={role}>{roleTypeValueOf(role).label}</div>
                    ))}
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell className={cellClass}>
                    {account.mailAddress}
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell>
                    <Right>
                      <IconButton
                        onClick={(e) =>
                          this.handleEditButtonClick(e, account.id)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={(e) =>
                          this.handlePasswordButtonClick(e, account.id)
                        }
                      >
                        <PasswordIcon />
                      </IconButton>
                      <IconButton
                        onClick={(e) =>
                          this.handleRemoveButtonClick(e, account.id)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Right>
                  </SmallPaddingTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </FixedTable>
      </Paper>
    );
  }

  renderRemoveDialog() {
    return (
      <Dialog
        onClose={this.handleRemoveDialogCloseButtonClick}
        open={this.state.removeDialogIsOpen}
      >
        <DialogTitle>アカウント削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            アカウントデータを削除します、よろしいですか？
          </DialogContentText>
          <DialogContentText>
            アカウントデータを削除した場合、このアカウントでログインすることができなくなります。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton onClick={this.handleRemoveDialogCloseButtonClick}>
            キャンセル
          </FlatPrimaryButton>
          <RaisedSecondaryButton
            onClick={this.handleRemoveDialogRevemoButtonClick}
            disabled={this.state.isDeleteButton}
          >
            削除する
          </RaisedSecondaryButton>
        </DialogActions>
      </Dialog>
    );
  }
}

AccountListComponent.propTypes = {
  accountList: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  authentication: PropTypes.object.isRequired,
  groupId: PropTypes.number.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onPasswordButtonClick: PropTypes.func.isRequired,
  onGoBack: PropTypes.func,
  opsNavigation: PropTypes.node.isRequired,
  role: PropTypes.string,
};

export default withInputFeedback(
  connect(mapStateToProps, mapDispatchToProps)(AccountListComponent)
);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    accountList: state.accountList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(formActions, dispatch),
      ...bindActionCreators(listActions, dispatch),
      reloadPage: (groupId) => {
        const searchParams = new URLSearchParams();
        searchParams.set("groupId", groupId);
        const search = searchParams.toString();
        dispatch(push({ search }));
      },
    },
  };
}
