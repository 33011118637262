import ServiceShubetsuCategoryType from "../../../app/domain/jigyosho/ServiceShubetsuCategoryType";
import TsushokeiServiceAbstract from "./abstract/TsushokeiServiceAbstract";
import IsoKaigoTaxiAbstract from "./abstract/IsoKaigoTaxiAbstract";
import SeikatsushienAbstract from "./abstract/SeikatsushienAbstract";
import HaishokuServiceAbstract from "./abstract/HaishokuServiceAbstract";
import KatsudoKayoinobaAbstract from "./abstract/KatsudoKayoinobaAbstract";
import SonotaChiikishigenAbstract from "./abstract/SonotaChiikishigenAbstract";
import TsushokeiServiceDetail from "./detail/TsushokeiServiceDetail";
import IsoKaigoTaxiDetail from "./detail/IsoKaigoTaxiDetail";
import SeikatsushienDetail from "./detail/SeikatsushienDetail";
import HaishokuServiceDetail from "./detail/HaishokuServiceDetail";
import KatsudoKayoinobaDetail from "./detail/KatsudoKayoinobaDetail";
import SonotaChiikishigenDetail from "./detail/SonotaChiikishigenDetail";
import TsushokeiServicePrint from "./print/TsushokeiServicePrint";
import IsoKaigoTaxiPrint from "./print/IsoKaigoTaxiPrint";
import SeikatsushienPrint from "./print/SeikatsushienPrint";
import HaishokuServicePrint from "./print/HaishokuServicePrint";
import KatsudoKayoinobaPrint from "./print/KatsudoKayoinobaPrint";
import SonotaChiikishigenPrint from "./print/SonotaChiikishigenPrint";
import HomonkeiServiceAbstract from "./abstract/HomonkeiServiceAbstract";
import HomonkeiServiceDetail from "./detail/HomonkeiServiceDetail";
import HomonkeiServicePrint from "./print/HomonkeiServicePrint";
import BarberAbstract from "./abstract/BarberAbstract";
import BarberDetail from "./detail/BarberDetail";
import BarberPrint from "./print/BarberPrint";
import MementoAbstract from "./abstract/MementoAbstract";
import MementoDetail from "./detail/MementoDetail";
import MementoPrint from "./print/MementoPrint";
import WatchAbstract from "./abstract/WatchAbstract";
import WatchDetail from "./detail/WatchDetail";
import WatchPrint from "./print/WatchPrint";
import KyotakukaigoshienjigyoshoAbstract from "./abstract/KyotakukaigoshienjigyoshoAbstract";
import KyotakukaigoshienjigyoshoDetail from "./detail/KyotakukaigoshienjigyoshoDetail";
import KyotakukaigoshienjigyoshoPrint from "./print/KyotakukaigoshienjigyoshoPrint";
import ShortstayAbstract from "./abstract/ShortstayAbstract";
import ShortstayDetail from "./detail/ShortstayDetail";
import ShortstayPrint from "./print/ShortstayPrint";
import MimamoriSuishinJigyoAbstract from "./abstract/MimamoriSuishinJigyoAbstract";
import MimamoriSuishinJigyoDetail from "./detail/MimamoriSuishinJigyoDetail";
import MimamoriSuishinJigyoPrint from "./print/MimamoriSuishinJigyoPrint";

const ServiceShubetsuCategoryContentViewType = {
  // 保険内サービス
  通所系サービス: {
    target: ServiceShubetsuCategoryType.通所系サービス,
    abstract: TsushokeiServiceAbstract,
    detail: TsushokeiServiceDetail,
    print: TsushokeiServicePrint,
  },
  訪問系サービス: {
    target: ServiceShubetsuCategoryType.訪問系サービス,
    abstract: HomonkeiServiceAbstract,
    detail: HomonkeiServiceDetail,
    print: HomonkeiServicePrint,
  },
  短期入所系サービス: {
    target: ServiceShubetsuCategoryType.短期入所系サービス,
    abstract: ShortstayAbstract,
    detail: ShortstayDetail,
    print: ShortstayPrint,
  },

  // 保険外サービス
  活動_通いの場: {
    target: ServiceShubetsuCategoryType.活動_通いの場,
    abstract: KatsudoKayoinobaAbstract,
    detail: KatsudoKayoinobaDetail,
    print: KatsudoKayoinobaPrint,
  },
  移送_介護タクシー: {
    target: ServiceShubetsuCategoryType.移送_介護タクシー,
    abstract: IsoKaigoTaxiAbstract,
    detail: IsoKaigoTaxiDetail,
    print: IsoKaigoTaxiPrint,
  },
  生活支援_自費ヘルプ: {
    target: ServiceShubetsuCategoryType.生活支援_自費ヘルプ,
    abstract: SeikatsushienAbstract,
    detail: SeikatsushienDetail,
    print: SeikatsushienPrint,
  },
  配食サービス: {
    target: ServiceShubetsuCategoryType.配食サービス,
    abstract: HaishokuServiceAbstract,
    detail: HaishokuServiceDetail,
    print: HaishokuServicePrint,
  },
  訪問理美容: {
    target: ServiceShubetsuCategoryType.訪問理美容,
    abstract: BarberAbstract,
    detail: BarberDetail,
    print: BarberPrint,
  },
  遺品整理_生前整理: {
    target: ServiceShubetsuCategoryType.遺品整理_生前整理,
    abstract: MementoAbstract,
    detail: MementoDetail,
    print: MementoPrint,
  },
  見守り_緊急通報: {
    target: ServiceShubetsuCategoryType.見守り_緊急通報,
    abstract: WatchAbstract,
    detail: WatchDetail,
    print: WatchPrint,
  },
  その他便利サービス: {
    target: ServiceShubetsuCategoryType.その他便利サービス,
    abstract: SonotaChiikishigenAbstract,
    detail: SonotaChiikishigenDetail,
    print: SonotaChiikishigenPrint,
  },
  見守り推進事業: {
    target: ServiceShubetsuCategoryType.見守り推進事業,
    abstract: MimamoriSuishinJigyoAbstract,
    detail: MimamoriSuishinJigyoDetail,
    print: MimamoriSuishinJigyoPrint,
  },

  // 居宅介護支援
  居宅介護支援: {
    target: ServiceShubetsuCategoryType.居宅介護支援,
    abstract: KyotakukaigoshienjigyoshoAbstract,
    detail: KyotakukaigoshienjigyoshoDetail,
    print: KyotakukaigoshienjigyoshoPrint,
  },
};

export function serviceShubetsuCategoryContentViewTypeOf(code) {
  return Object.keys(ServiceShubetsuCategoryContentViewType)
    .map((key) => ServiceShubetsuCategoryContentViewType[key])
    .find((type) => type.target.code == code);
}

export default ServiceShubetsuCategoryContentViewType;
